export const DATA_PRODUCTS: {
  [key: string]: {
    classnames?: string;
    title: string;
    header: { text: string; color?: string };
    description: string;
    isNotCollapsed: boolean;
    isTabInDesktop: boolean;
    containsImage: boolean;
    imgDesktop: string | null;
    childrenData: {
      description_desktop: string | null;
      extend_description_desktop: string | null;
      icon: string | null;
      title: string | null;
      subtitle: string | null;
      classname: string | null;
      fill: string | null;
      img: string | null;
      background: string | null;
    }[];
    buttons:
      | {
          text: string;
          modifier: string | null;
          action: (section?: string) => void;
        }[]
      | null;
    openToggle: boolean;
  }[];
} = {
  auditory: [
    {
      isNotCollapsed: true,
      imgDesktop: null,
      openToggle: false,
      containsImage: false,
      isTabInDesktop: false,
      classnames: 'my-10',
      title: 'Las funcionalidades mas destacadas',
      header: {
        text: 'Características del producto',
        color: 'text-violet-400',
      },
      description:
        'Nuestro software de auditoría te va a permitir llevar un control de las liquidaciones de los prestadores de acuerdo a las reglas que definas.',
      childrenData: [
        {
          icon: 'checkCircle',
          img: 'icon-arrow.png',
          title: 'Estandariza las reglas de las auditorias',
          subtitle:
            'Ingresa al sistema las normativas claves para la autorización de prestaciones.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: '!text-amber-600 !bg-amber-50',
          fill: '#D69330',
          background: 'bg-amber-200',
        },
        {
          icon: 'checkCircle',
          img: 'icon-message.png',
          title: 'Audita, liquida y paga prestaciones odontológicas',
          subtitle:
            'Automatiza y optimiza el proceso de liquidación, asegurando precisión.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: 'text-violet-600 bg-violet-50',
          fill: '#805ad5',
          background: 'bg-violet-200',
        },
        {
          icon: 'checkCircle',
          img: 'icon-smile.png',
          title: 'Crea informes rápidos y detallados',
          subtitle:
            'Generar informes facilita la toma de decisiones y cálculos estadísticos.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: 'text-green-600 bg-green-50',
          fill: '#38a169',
          background: 'bg-green-200',
        },
      ],
      buttons: [],
    },
  ],
  home: [
    {
      title: 'El software que te mereces',
      header: { text: 'Gestión odontológica' },
      description:
        'Ten en una sola ventana todo el control de tu clínica para una mejor gestión.',
      childrenData: [
        {
          title: 'Organiza tu agenda y turnos',
          subtitle:
            'Agenda turnos en forma eficiente, mantene tu agenda siempre completa.',
          description_desktop:
            'Agenda turnos de forma eficiente, programa fácilmente citas con tus pacientes, gestiona recordatorios y sincroniza tu horario con tu equipo.',
          extend_description_desktop:
            'Con nuestra agenda, ten la flexibilidad necesaria para adaptarte a los cambios de último momento y reorganizar tu horario con facilidad.',
          img: 'schedule-product.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Información de tus pacientes',
          subtitle:
            'Gestiona todos tus pacientes de la manera más fácil y más cómoda.',
          description_desktop:
            'Gestiona todos tus pacientes de manera fácil y cómoda, saca provecho a las  funciones avanzadas de búsqueda y filtrado.',
          extend_description_desktop:
            'Incluye desde los datos personales hasta historias clínicas detalladas, registra tratamientos anteriores y lleva un seguimiento de la evolución de tus pacientes.',
          img: 'patient-product.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Estadísticas para medir tu éxito',
          subtitle:
            'Mirá las estadísticas que te permitirán responder a muchas de tus preguntas, en un sólo lugar.',
          description_desktop:
            'Explora el poder de los datos en la sección de estadísticas. Comprende mejor el desempeño de tu consultorio, compara los ingresos mes a mes además de evaluar los presupuestos emitidos y las prestaciones realizadas.',
          extend_description_desktop:
            'Descubre como las estadísticas y finanzas pueden trasformar tus resultados.',
          img: 'statistics-product.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
      ],
      containsImage: true,
      isTabInDesktop: true,
      imgDesktop: null,
      openToggle: false,
      isNotCollapsed: false,
      buttons: [
        {
          text: 'Tarifas y planes',
          action: () => window.open('https://payments.bilog.com.ar'),
          modifier: null,
        },
        {
          text: 'Ver más >',
          modifier: 'transparent',
          action: (section) => (window.location.href = `/${section}`),
        },
      ],
    },
    {
      isNotCollapsed: true,
      imgDesktop: null,
      openToggle: false,
      containsImage: false,
      isTabInDesktop: false,
      title: 'Envía los recordatorios de turnos con WhatsApp',
      header: { text: 'Recordatorio de turnos' },
      description:
        'Descargate la app de Bilog y envía los recordatorios de turnos de manera super fácil desde la palma de tu mano en donde quieras y cuando quieras.',
      childrenData: [
        {
          icon: 'checkCircle',
          img: 'icon-arrow.png',
          title: 'MEJORA EL PRESENTISMO',
          subtitle:
            'WhatsApp tiene una tasa de apertura superior al 90%, lo que garantiza que tus pacientes leerán el recordatorio del turno.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: '!text-amber-600 !bg-amber-50',
          fill: '#D69330',
          background: 'bg-amber-200',
        },
        {
          icon: 'checkCircle',
          img: 'icon-message.png',
          title: 'AUMENTA LA COMUNICACIÓN',
          subtitle:
            'Atiende más consultas con la tranquilidad de que tus pacientes sabrán de cualquier confirmación o modificación de turnos.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: 'text-violet-600 bg-violet-50',
          fill: '#805ad5',
          background: 'bg-violet-200',
        },
        {
          icon: 'checkCircle',
          img: 'icon-smile.png',
          title: 'APORTA AGILIDAD A TU CLÍNICA',
          subtitle:
            'Nuestra agenda se actualiza de forma automática en base a las respuestas de tus pacientes.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: 'text-green-600 bg-green-50',
          fill: '#38a169',
          background: 'bg-green-200',
        },
      ],
      buttons: [
        {
          text: 'Descargá al App',
          action: () =>
            window.open(
              /Android/.test(navigator.userAgent)
                ? 'https://play.google.com/store/apps/details?id=com.bilog.gomobile'
                : 'https://apps.apple.com/uy/app/bilog-gesti%C3%B3n-odontol%C3%B3gica/id1554140449',
              '_blank',
            ),
          modifier: null,
        },
      ],
    },
    {
      title: 'Innovación y eficiencia en todas tus auditorías',
      header: { text: 'Auditoría Odontológica' },
      description:
        'Lleva la auditoría de las liquidaciones de los prestadores de acuerdo a las reglas que tu definas.',
      childrenData: [
        {
          title: 'Audita prestaciones',
          subtitle:
            'Controlar que las prestaciones enviadas por el prestador sean válidas.',
          description_desktop: null,
          extend_description_desktop: null,
          img: 'innovation-product.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Aumenta la eficiencia',
          subtitle:
            'Procesar los rechazos o autorizaciones de las prácticas y posteriormente proceder a la liquidación de honorarios de los prestadores.',
          description_desktop: null,
          extend_description_desktop: null,
          img: 'innovation-product.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Software Prestadores',
          subtitle:
            'Los socios pueden enviar la facturación en soporte magnético, vía mail o por la app de Bilog ahorrando tiempo de carga de datos.',
          description_desktop: null,
          extend_description_desktop: null,
          img: 'innovation-product.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
      ],
      containsImage: true,
      imgDesktop: 'innovation-product.png',
      openToggle: true,
      isNotCollapsed: false,
      isTabInDesktop: false,
      buttons: [
        {
          text: 'Consultar con ventas',
          action: () => window.open('https://wapp.ly/l/20820ZeQv', '_blank'),
          modifier: null,
        },
        {
          text: 'Ver más >',
          modifier: 'transparent',
          action: () => (window.location.href = '/auditory'),
        },
      ],
    },
  ],
  patients: [
    {
      title: 'Cuida cada sonrisa, simplifica cada paso',
      header: { text: 'Registro y datos del paciente' },
      description:
        'Desde la primera consulta hasta el seguimiento post-tratamiento, nuestro software te permite gestionar fácilmente la información de cada paciente.',
      childrenData: [
        {
          icon: 'checkCircle',
          img: 'scan-face.png',
          title: 'DATOS PERSONALES',
          subtitle:
            'Completa la ficha de tus pacientes para tener toda su información en un solo click y brindar experiencias personalizadas.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: '!text-amber-600 !bg-amber-50',
          fill: '#D69330',
          background: 'bg-amber-200',
        },
        {
          icon: 'checkCircle',
          img: 'recip-text.png',
          title: 'INFORMACIÓN AFILIAROTIA Y DE FACTURACIÓN',
          subtitle:
            'Ten toda la información que necesitas para que tus pacientes te puedan pagar.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: 'text-violet-600 bg-violet-50',
          fill: '#805ad5',
          background: 'bg-violet-200',
        },
        {
          icon: 'checkCircle',
          img: 'badge-check.png',
          title: 'DATOS PARTICULARES',
          subtitle:
            'Brinda una atención única, conoce a tu paciente y registra lo que necesitas saber para cada una de sus consultas.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: 'text-green-600 bg-green-50',
          fill: '#38a169',
          background: 'bg-green-200',
        },
      ],
      containsImage: false,
      imgDesktop: null,
      openToggle: false,
      isNotCollapsed: true,
      isTabInDesktop: false,
      buttons: null,
    },
  ],
  statistics: [
    {
      title: 'Conoce todo lo que puedes hacer',
      header: { text: 'Funcionalidades' },
      description:
        'Ten en una sola ventana todo el control de tu clínica para una mejor gestión.',
      childrenData: [
        {
          title: 'Compara y analiza tus finanzas ',
          subtitle:
            'Recopila datos relevantes, desde facturaciones hasta los ingresos provenientes de obras sociales. Adicionalmente, realiza un análisis detallado de tu crecimiento mediante comparaciones entre distintos periodos de tiempo.',
          description_desktop:
            'Agenda turnos de forma eficiente, programa fácilmente citas con tus pacientes, gestiona recordatorios y sincroniza tu horario con tu equipo.',
          extend_description_desktop:
            'Con nuestra agenda, ten la flexibilidad necesaria para adaptarte a los cambios de último momento y reorganizar tu horario con facilidad.',
          img: 'statistics-finances-product.webp',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Accede a métricas diarias',
          subtitle:
            'Obtén una visión detallada de tu consulta médica con nuestras métricas diarias. Con Bilog, podrás acceder fácilmente a información sobre tu práctica, incluyendo el número de pacientes atendidos, la cantidad de pacientes nuevos que han ingresado en la semana, los turnos agendados y mucho más. ',
          description_desktop:
            'Gestiona todos tus pacientes de manera fácil y cómoda, saca provecho a las  funciones avanzadas de búsqueda y filtrado.',
          extend_description_desktop:
            'Incluye desde los datos personales hasta historias clínicas detalladas, registra tratamientos anteriores y lleva un seguimiento de la evolución de tus pacientes.',
          img: 'statistics-metrics-product.webp',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Analiza prestaciones realizadas',
          subtitle:
            'Nuestra plataforma te ofrece datos claros y comprensibles sobre cuáles son los servicios más demandados en tu consulta y cómo se brindan. Utilizando información detallada sobre los importes abonados por pacientes y los seguros médicos involucrados, Bilog te proporciona una visión completa de las tendencias en la atención que ofreces.',
          description_desktop:
            'Explora el poder de los datos en la sección de estadísticas. Comprende mejor el desempeño de tu consultorio, compara los ingresos mes a mes además de evaluar los presupuestos emitidos y las prestaciones realizadas.',
          extend_description_desktop:
            'Descubre como las estadísticas y finanzas pueden trasformar tus resultados.',
          img: 'statistics-benefits-product.webp',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Observa datos de presupuestos',
          subtitle:
            'Puedes llevar un registro exhaustivo de tus presupuestos de una manera que potencie al máximo la productividad de tu profesión. Mide los porcentajes de los presupuestos emitidos por movimientos, saldados, sin movimientos, por seguros médicos y mucho más.',
          description_desktop:
            'Explora el poder de los datos en la sección de estadísticas. Comprende mejor el desempeño de tu consultorio, compara los ingresos mes a mes además de evaluar los presupuestos emitidos y las prestaciones realizadas.',
          extend_description_desktop:
            'Descubre como las estadísticas y finanzas pueden trasformar tus resultados.',
          img: 'statistics-budgets-product.webp',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
      ],
      containsImage: true,
      imgDesktop: null,
      isTabInDesktop: true,
      openToggle: false,
      isNotCollapsed: false,
      buttons: [
        {
          text: 'Tarifas y planes',
          action: () => window.open('https://payments.bilog.com.ar'),
          modifier: null,
        },
        {
          text: 'Ver más >',
          modifier: 'transparent',
          action: (section) => (window.location.href = `/${section}`),
        },
      ],
    },
  ],
  shield: [
    {
      title: 'Supervisores: Líderes en Gestión y Seguridad',
      header: { text: 'Rol de supervisor' },
      description:
        'El usuario supervisor tiene el mayor nivel de control dentro del sistema, brindando apoyo y supervisión donde sea necesario. Esta persona puede realizar las siguientes funciones:',
      childrenData: [
        {
          icon: 'checkCircle',
          img: 'scan-face.png',
          title: 'Administrar y gestionar todos los aspectos del sistema.',
          subtitle: 'Administrar y gestionar todos los aspectos del sistema.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: '!text-amber-600 !bg-amber-50',
          fill: '#D69330',
          background: 'bg-amber-200',
        },
        {
          icon: 'checkCircle',
          img: 'recip-text.png',
          title: 'SUPERVISAR LA ACTIVIDAD DE LA CLINICA EN LAS SUCURSALES.',
          subtitle: 'Asignar y modificar permisos para otros usuarios.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: 'text-violet-600 bg-violet-50',
          fill: '#805ad5',
          background: 'bg-violet-200',
        },
        {
          icon: 'checkCircle',
          img: 'badge-check.png',
          title: 'ANTECEDENTES DE ENFERMEDADES Y DATOS PARTICULARES',
          subtitle: 'Supervisar la actividad de la cinca en las sucursales.',
          description_desktop: null,
          extend_description_desktop: null,
          classname: 'text-green-600 bg-green-50',
          fill: '#38a169',
          background: 'bg-green-200',
        },
      ],
      containsImage: false,
      imgDesktop: null,
      openToggle: false,
      isNotCollapsed: true,
      isTabInDesktop: false,
      buttons: null,
    },
    {
      title: 'Permisos a medida para tu equipo',
      header: { text: 'Personalización de permisos' },
      description:
        'Cada miembro de tu equipo tiene un rol único. Por eso, ofrecemos un sistema flexible para personalizar sus permisos según sus características:',
      childrenData: [
        {
          title: 'Sucursales de trabajo',
          subtitle:
            'Asigna a los usuarios a las distintas sucursales de tu clínica. Así, cada uno tiene acceso únicamente a la información relevante para su ubicación.',
          description_desktop: null,
          extend_description_desktop: null,
          img: 'shield.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Profesión y roles',
          subtitle:
            'Configura los permisos según la profesión y el rol específico de cada usuario.',
          description_desktop: null,
          extend_description_desktop: null,
          img: 'shield.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
        {
          title: 'Facilidad de modificación',
          subtitle:
            'Los permisos pueden ser ajustados en cualquier momento por los supervisores, permitiendo una rápida adaptación a cambios en el equipo.',
          description_desktop: null,
          extend_description_desktop: null,
          img: 'shield.png',
          icon: null,
          classname: null,
          fill: null,
          background: null,
        },
      ],
      containsImage: true,
      imgDesktop: 'shield.png',
      openToggle: true,
      isNotCollapsed: false,
      isTabInDesktop: false,
      buttons: null,
    },
  ],
};
