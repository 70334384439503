export const featuredItems: {
  [key: string]: {
    header: string;
    title: string;
    description?: string;
    img?: string;
    styles?: string;
    items: { title: string; description: string }[];
  };
} = {
  about: {
    header: '¿Por qué elegirnos?',
    title: 'Nos comprometemos con nuestros usuarios',
    img: 'purposes',
    styles:'border-s-blue-100 md:border-s-blue-400 border-s-8',
    items: [
      {
        title: 'Misión',
        description:
          'Facilitar la gestión y administración de consultorios y clínicas para que nuestros usuarios hagan foco en lo que les importa.',
      },
      {
        title: 'Visión',
        description:
          'Lo que nos motiva a diario es llegar a ser la empresa líder en innovación de la gestión y administración de la salud en LATAM.',
      },
      {
        title: 'Objetivos',
        description:
          'Nuestro objetivo es ayudar con las tareas administrativas para que los profesionales se dediquen sin preocupaciones extras a ser los mejores en su área.',
      },
    ],
  },
  patients: {
    header: 'Prestaciones',
    title: 'Amplia la gama de tratamientos',
    description:
      'Desde la primera consulta hasta el seguimiento post-tratamiento, nuestro software te permite gestionar fácilmente la información de cada paciente.',
    img: 'benefits',
    items: [
      {
        title: 'Prestaciones  más específicas',
        description:
          'Completas y con toda la información que necesitas para llevar registro del tratamiento.',
      },
      {
        title: 'Lista de prestaciones realizadas',
        description:
          'Registra, edita y observa la lista de prestaciones realizadas por paciente.',
      },
      {
        title: 'Registro de accionables',
        description:
          'Incluye si la prestación ya está realizada, se está realizando o está programada.',
      },
    ],
  },
  auditory: {
    header: 'Paquetes adicionales',
    title: 'Conoce nuestros packs adicionales',
    description: 'Para potenciar el software, consulta con nuestro equipo de ventas sobre los paquetes disponibles para ti.  ',
    styles:' md:border-s-8 md:border-s-violet-400 lg:border-s-violet-400 2xl:border-s-violet-400',
    items: [
      {
        title: 'Envío de liquidaciones por mail',
        description:
          'Envía de manera eficiente las liquidaciones directamente a cada prestador a través de correo electrónico.',
      },
      {
        title: 'Factura electrónica',
        description:
          'Contrata el pack de factura electrónica para declarar los porcentajes cobrados al prestador por la liquidación.',
      },
    ],
  },
};
