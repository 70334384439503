import {
  Percentage_100,
  Account,
  Calendar,
  Whatsapp,
  ClipboardPlus,
  Clipboard,
  Tooth,
  CheckCircle,
  Laboratory,
  Percentage_54,
  Plus_15,
  Plus_25,
  Plus_20k,
  Plus_3k,
  Star,
  Shield,
  Clock,
  CircleCheck,
  AuditoryShield,
  AuditoryClock,
  AuditoryCircleCheck,
} from '#assets/icons';

export const data: {
  title: string;
  description: string;
  img: string;
  platforms: {
    title: string;
    icon: string;
    classname: string;
    fill: string;
  }[];
}[] = [
  {
    title: 'Agenda diaria',
    img: 'day-schedule.png',
    description:
      'Nuestra agenda diaria te ofrece una visión completa de tus citas, permitiéndote saber lo esencial para atender a tus pacientes.',
    platforms: [
      {
        title: 'Móvil',
        icon: 'mobile',
        classname: '!text-amber-600 !bg-amber-50',
        fill: '#ffff',
      },
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
      {
        title: 'Escritorio',
        icon: 'desktop',
        classname: '!text-green-600 !bg-green-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Agenda semanal',
    img: 'weekly-schedule.png',
    description:
      'La agenda semanal es tu aliado perfecto para planificar y optimizar tu semana de trabajo, con una vista completa de los turnos.',
    platforms: [
      {
        title: 'Móvil',
        icon: 'mobile',
        classname: '!text-amber-600 !bg-amber-50',
        fill: '#ffff',
      },
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
      {
        title: 'Escritorio',
        icon: 'desktop',
        classname: '!text-green-600 !bg-green-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Agenda por profesional',
    img: 'prof-schedule.png',
    description:
      'Si son más de un profesional en la clínica, esta opción es perfecta, visualiza la agenda diaria de más de un profesional en un solo lugar.',
    platforms: [
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
    ],
  },
];

export const remembers: {
  [key: string]: { title: string; description: string; icon: any }[];
} = {
  schedule: [
    {
      title: 'Recordatorios de turnos',
      description:
        'Envía recordatorio de turnos a tus pacientes por WhatsApp o mail.',
      icon: Whatsapp,
    },
    {
      title: 'Confirmación de turnos',
      description:
        'Garantiza puntualidad y tranquilidad a tus pacientes con la confirmación de turnos.',
      icon: Calendar,
    },
    {
      title: 'Presentismo',
      description:
        'Registra la asistencia de pacientes y ten un seguimiento de la puntualidad en las citas.',
      icon: Account,
    },
  ],
  reports: [
    {
      title: 'De tus pacientes felices',
      description:
        'Crea informes personalizados según las necesidades de tu clínica, incluyendo informes de cumpleaños para mejorar la experiencia del paciente.',
      icon: Percentage_100,
    },
    {
      title: 'Reportes de auditor',
      description:
        'Supervisa el estado de los pedidos de laboratorio, desde los pendientes de entrega hasta los totales recibidos y pagados.',
      icon: Account,
    },
    {
      title: 'Informes de laboratorio',
      description:
        'Supervisa el estado de los pedidos de laboratorio, desde los pendientes de entrega hasta los totales recibidos y pagados.',
      icon: Laboratory,
    },
  ],
  patients_budgets: [
    {
      title: 'Nuevo presupuesto',
      description:
        'Crea un nuevo presupuesto para presentar de manera  rápida y personalizada.',
      icon: ClipboardPlus,
    },
    {
      title: 'Selecciona las prestaciones',
      description:
        'Explora opciones de tratamiento, selecciona y edita todas las prestaciones.',
      icon: Tooth,
    },
    {
      title: 'Presenta el resumen',
      description:
        'Genera un presupuesto claro y detallado listo para utilizar y editar como y cuando desees.',
      icon: CheckCircle,
    },
  ],
  patients_laboratories: [
    {
      title: 'Trabajo para laboratorio',
      description:
        'Lleva un registro de los trabajos y pedidos que requieres realizar para tu paciente.',
      icon: Laboratory,
    },
    {
      title: 'Pura claridad',
      description:
        'Registra fechas, pagos y entregas en relación a los laboratorios pedidos.',
      icon: Percentage_100,
    },
    {
      title: 'Descarga el pedido',
      description:
        'Descarga el trabajo para laboratorio y envíaselo a tu laboratorio de confianza, con mayor claridad.',
      icon: Clipboard,
    },
  ],
  statistics: [
    {
      title: 'Reducción de ausentismo',
      description:
        'Logra gestionar e identificar los servicios ideales para tus pacientes.',
      icon: Percentage_54,
    },
    {
      title: 'Aumento de la rentabilidad',
      description:
        'Potencia la rentabilidad de tu negocio llevando tus presupuestos y más.',
      icon: Plus_25,
    },
    {
      title: 'Aumento en la productividad',
      description:
        'Mayor uso de recursos disponibles en el menor tiempo posible.',
      icon: Plus_15,
    },
  ],
  about: [
    {
      title: 'Clientes',
      description:
        'Nos eligen para brindarles las mejores soluciones en gestión odontológica.',
      icon: Plus_3k,
    },
    {
      title: 'Usuarios',
      description:
        'Disponen de nuestra aplicación y dan uso de nuestra aplicación día a día.',
      icon: Plus_20k,
    },
    {
      title: 'Respaldado por los mejores',
      description:
        'Estamos orgullosos de ser la solución de trabajo visual número uno para la empresa.',
      icon: Star,
    },
  ],
  shield: [
    {
      title: 'Protección de Datos',
      description:
        'La información de tus pacientes y de tu clínica está protegida.',
      icon: Shield,
    },
    {
      title: 'Eficiencia Operativa',
      description:
        'Personaliza permisos, reduce errores y mejora la productividad de tu consulta.',
      icon: Clock,
    },
    {
      title: 'Tranquilidad para todos',
      description:
        'Genera confianza en el uso del sistema y en la calidad de los servicios de tu clínica.',
      icon: CircleCheck,
    },
  ],
  auditory: [
    {
      title: 'Datos protegidos',
      description:
        'Seguridad garantizada de los datos en la nube para todas las partes involucradas.',
      icon: AuditoryShield,
    },
    {
      title: 'Interacción rapida y efectiva',
      description:
        'Descripción de 2 o 3 líneas que explique aquello que deseamos destacar.',
      icon: AuditoryClock,
    },
    {
      title: 'Filtrado de prestaciones',
      description:
        'Elige aceptar o rechazar prestaciones del profesional según la obra social.',
      icon: AuditoryCircleCheck,
    },
  ],
};

export const otherSections: {
  title: string;
  description: string;
  icon: any;
  link: string;
}[] = [
  {
    title: 'Pacientes',
    description: 'Gestiona toda la información de tus pacientes',
    icon: 'user',
    link: '/patients',
  },
  {
    title: 'Estadísticas',
    description: 'Lleva un registro de tus finanzas.',
    icon: 'statistics',
    link: '/statistics',
  },
  {
    title: 'Seguridad',
    description: 'Adapta los permisos según tus preferencias.',
    icon: 'shield',
    link: '/security',
  },
];
