export const features: {
  img: string;
  badge: string;
  classnames: string;
  title: string;
  description: string;
}[] = [
  {
    title: 'Más organización y menos papel',
    description:
      'Registra la historia clínica de tu paciente. Podrás consultarla y editarla cada vez que lo necesites con un solo click.',
    img: 'hist-cli',
    badge: 'Historia clínica',
    classnames: '!text-amber-600 !bg-amber-50',
  },
  {
    title:
      'Controla y registra los pagos de tu paciente de forma más eficiente.',
    description:
      'Visualiza y registra los pagos de cada paciente, con la opción de añadir nuevos pagos.',
    img: 'payments',
    badge: 'Pagos',
    classnames: 'text-violet-600 bg-violet-50',
  },
  {
    title: 'Control total de turnos',
    description:
      'Consulta de forma rápida y precisa todos los turnos reservados por cada paciente, encuentra su asistencia y observaciones en un solo lugar.',
    img: 'schedule',
    badge: 'Turnos',
    classnames: 'text-green-600 bg-green-50',
  },
  {
    title: 'Imágenes nítidas, resultados precisos',
    description:
      'Nuestra plataforma te permite almacenar y visualizar imágenes de alta calidad para un diagnóstico preciso.',
    img: 'galery',
    badge: 'Galería',
    classnames: 'text-pink-600 bg-pink-50',
  },
];

export const odontogram: {
  header: string;
  title: string;
  description: string;
  check_list: string[];
} = {
  header: 'Odontograma',
  title: 'Visualiza la salud bucal con claridad',
  description:
    'Nuestra interfaz de odontograma te permite registrar y seguir la evolución de la salud bucal de tus pacientes de manera visual e intuitiva, facilitando el diagnóstico y la planificación del tratamiento',
  check_list: [
    'Odontograma primario y permanente.',
    'Prestaciones impactadas en tu odontograma.',
    'Vista lineal o agrupada.',
    'Detalles y observaciones incluidos.',
    'Visualiza la prestación por diente, sector y/o cara.',
    'Indica la prestación por diente, sector y/o cara.',
  ],
};

export const recipes: {
  header: string;
  title: string;
  description: string;
  check_list: string[];
} = {
  header: 'Recetas',
  title: 'Emite recetas para tus pacientes',
  description:
    'Facilita el proceso de prescripción médica para tus pacientes con nuestro módulo de recetas digitales',
  check_list: [
    'Genera recetas de manera rápida y sencilla.',
    'Acceso rápido al historial de prescripciones del paciente para una mejor continuidad.',
    'Recetas que cumplen con las normativas vigentes.',
    'Información completa y actualizada sobre medicamentos y dosificaciones. ',
  ],
};
